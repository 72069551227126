/* eslint-disable */

const $checkboxes = $('.lumi-checkbox__label');
$(document).ready(function () {
  $checkboxes.keydown(function (e) {
    // if tab or enter, then we want to trigger checkbox
    const key = e.which;
    if (key === 13 || key === 32) {
      e.preventDefault();
      const siblingInput = $(e.target).siblings('input');
      siblingInput[0].checked = !siblingInput[0].checked;
      siblingInput.trigger('change');
    }
  });
});
