/* eslint-disable */

var ACTIVE = 'tab-1';

const $tab1 = $('#tab-label-1');
const $tab2 = $('#tab-label-2');
const $tab3 = $('#tab-label-3');

const $content1 = $('#tab-content-1');
const $content2 = $('#tab-content-2');
const $content3 = $('#tab-content-3');

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const activeTab = urlParams.get('active_tab');

$(document).ready(function () {
  if (activeTab === '2') {
    $tab2.addClass('tab-active');
    $content1.addClass('tab-content-hide');
    $content3.addClass('tab-content-hide');
  } else if (activeTab === '3') {
    $tab3.addClass('tab-active');
    $content1.addClass('tab-content-hide');
    $content2.addClass('tab-content-hide');
  } else {
    $tab1.addClass('tab-active');
    $content2.addClass('tab-content-hide');
    $content3.addClass('tab-content-hide');
  }

  $tab1.click(function () {
    ACTIVE = 'tab-1';
    $tab1.addClass('tab-active');
    $tab2.removeClass('tab-active');
    $tab3.removeClass('tab-active');
    $content1.removeClass('tab-content-hide');
    $content2.addClass('tab-content-hide');
    $content3.addClass('tab-content-hide');
  });
  $tab2.click(function () {
    ACTIVE = 'tab-2';
    $tab1.removeClass('tab-active');
    $tab2.addClass('tab-active');
    $tab3.removeClass('tab-active');
    $content1.addClass('tab-content-hide');
    $content2.removeClass('tab-content-hide');
    $content3.addClass('tab-content-hide');
  });
  $tab3.click(function () {
    ACTIVE = 'tab-3';
    $tab1.removeClass('tab-active');
    $tab2.removeClass('tab-active');
    $tab3.addClass('tab-active');
    $content1.addClass('tab-content-hide');
    $content2.addClass('tab-content-hide');
    $content3.removeClass('tab-content-hide');
  });
});
