/* eslint-disable */

$('.carousel').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  dots: false,
  centerMode: true,
  arrows: false,
  focusOnSelect: true,
  autoplay: true,
  autoplaySpeed: 3600,
  responsive: [
    {
      breakpoint: 960,
      settings: {
        arrows: false,
        centerMode: true,
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 610,
      settings: {
        arrows: false,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
});

// Checks to see if a text-overflow is in effect
$.fn.overflown = function () {
  var e = this[0];
  return e.scrollHeight > e.clientHeight || e.scrollWidth > e.clientWidth;
};

$(document).ready(function () {
  for (let i = 1; i < 18; i++) {
    let el = document.getElementById('testimonial-body-' + i);
    let isOverflown = $('#' + el.id).overflown();
    // Check if text is trancated, if true -> add class to 'show more' button.
    if (isOverflown) {
      $('#show-more-' + i).addClass('show-more-reveal');
    }
  }
});

// Checks which 'show more' button you have clicked, and expands text & removes button.
$(document).ready(function () {
  $('.show-more').click(function () {
    let id = this.id.substring(10, 12);
    $('#testimonial-body-' + id).addClass('expand-clamp');
    $('#show-more-' + id).removeClass('show-more-reveal');
  });
});
