/* eslint-disable */

var EXPANDED = true;

const $drawerCollapse = $('#drawer-collapse-button');
const $drawerButtons = $('#drawer-buttons');
const $chevron = $('#chevron');

$(document).ready(function () {
  $drawerCollapse.click(function () {
    if (EXPANDED) {
      $drawerButtons.addClass('hide-buttons');
      $chevron.addClass('chevron-reversed');
      EXPANDED = false;
    } else {
      $drawerButtons.removeClass('hide-buttons');
      $chevron.removeClass('chevron-reversed');
      EXPANDED = true;
    }
  });

  if ($(window).width() <= 1024) {
    setTimeout(function () {
      if (EXPANDED) {
        $label.addClass('hide-label');
        $label2.addClass('hide-label');
        $label3.addClass('hide-label');
        EXPANDED = false;
      }
    }, 5000);
  }
});
