/* eslint-disable */
const links = [
  'browse-loan-types',
  'versus',
  'blog-posts',
  'business-advice',
  'how-to-get',
  'types-of-small',
  'loan-options',
  'loan requirements',
  'how-to-apply',
  'increase-odds',
];

$(document).ready(function () {
  for (let i = 0; i < links.length; i++) {
    $('#' + links[i] + '-btn').click(function () {
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $('#' + links[i]).offset().top,
        },
        600,
      );
    });
  }
});

const termsAndConditionsButton = '#ks-tc-btn';

$(document).ready(function () {
  $(termsAndConditionsButton).click(function () {
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $('#ks-tc').offset().top,
      },
      600,
    );
  });
});
