/* eslint-disable */

$('#kickstarter_carousel').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: false,
  dots: false,
  arrows: true,
  autoplay: false,
  adaptiveHeight: false,
  responsive: [
    {
      breakpoint: 768,
      centerMode: true,
      settings: {
        arrows: false,
        dots: true,
      },
    },
  ],
});
