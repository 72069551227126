/* eslint-disable */
const $applyButton = $('#qualify-apply__button');
const $applyButtonModal = $('#qualify-apply__button-modal');

const $checkBoxes = $('.point-count-sidebar input:checkbox');
const $checkBoxesModal = $('.point-count-modal input:checkbox');

$(document).ready(function () {
  $applyButton.attr('disabled', true);
  $applyButton.click(apply);
  $checkBoxes.change(checkIfCanApply);
});

$(document).ready(function () {
  $applyButtonModal.attr('disabled', true);
  $applyButtonModal.click(apply);
  $checkBoxesModal.change(checkIfCanApplyModal);
});

function isAllChecked() {
  return $('.point-count-sidebar input:checkbox:checked').length === 3;
}

function isAllModalChecked() {
  return $('.point-count-modal input:checkbox:checked').length === 3;
}

function checkIfCanApply() {
  if (isAllChecked()) {
    $applyButton.attr('disabled', false);
  } else {
    $applyButton.attr('disabled', true);
  }
}

function checkIfCanApplyModal() {
  if (isAllModalChecked()) {
    $applyButtonModal.attr('disabled', false);
  } else {
    $applyButtonModal.attr('disabled', true);
  }
}

function apply(e) {
  e.preventDefault();
  if (isAllChecked() || isAllModalChecked()) {
    localStorage.setItem(
      'initial_application_data',
      JSON.stringify({
        loan: {
          do_you_qualify: true,
        },
        segment: {
          flow_name: 'onboarding',
        },
      }),
    );
    window.location = '/apply';
  }
  return;
}
